<template>
	<nav v-if="breadcrumbs.length" class="breadcrumbs">
		<div class="row">
			<div class="columns column12">
				<ol>
					<li>
						<nuxt-link :to="localePath('/')" class="active">
							{{ defaults[locale].website.hotelName ?? 'Home' }}
						</nuxt-link>
						<span> ></span>
					</li>
					<li v-for="(item, index) in breadcrumbs" :key="item.filename">
						<nuxt-link v-if="index + 1 !== breadcrumbs.length" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
						<span v-else>
							{{ item.header }}
						</span>
						<span v-if="!(index == breadcrumbs.length - 1)">></span>
					</li>
				</ol>
			</div>
		</div>
	</nav>
</template>

<script setup>
const { locale } = useI18n();
const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();

defineProps({
	breadcrumbs: { type: Array, default: () => [] },
});
</script>

<style lang="scss" scoped>
.breadcrumbs {
	background: rgb(243 243 243 / 60%);
	padding: 30px 0;
	text-transform: uppercase;
	font-size: 13px;

	a,
	span {
		margin: 0 10px 0 0;
	}

	&.no-slider {
		margin-top: 100px;
	}

	ol {
		list-style-type: none;

		li {
			float: left;
		}
	}
}
</style>
